import React from "react";
import { Image } from "antd";
import { baseUrl } from "../api/axios";

const CompanyLogo = () => {
  return (
    <div className="site-logo">
      <Image
        className="site-logo-img"
        loading="eager"
        preview={false}
        fallback={`${baseUrl}/trm_logo.png`}
        title="Trillium Rack Management"
        src={`${baseUrl}/site_logo.png`}
      />
    </div>
  );
};

export default CompanyLogo;
