import React from "react";
import { Layout, Typography } from "antd";
import { Link } from "react-router-dom";
const { Footer } = Layout;
const { Text } = Typography;

const SiteFooter = () => {
  return (
    <Footer className="footer">
      <Text type="secondary" style={{ color: "#ffffff" }}>
        Crafted for{" "}
        <Link
          to={"https://www.calflow.com/"}
          target="_blank"
          style={{ color: "wheat" }}
        >
          Trillium Measurement and Control
        </Link>{" "}
        by Clark 2024 (04060310)
      </Text>
    </Footer>
  );
};

export default SiteFooter;
