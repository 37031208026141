import axios from "axios";
const BASE_URL = "https://ml-server.demo.thinksmall.site";
//const BASE_URL = "http://localhost:3500";

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  timeout: 1000 * 60,
});

export const baseUrl = BASE_URL;
